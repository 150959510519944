import { createSelector } from 'reselect';
import { USER_ROLES } from 'swag-common/constants/user-roles';
const userSelector = state => state.user;
const hasStoreSelector = createSelector(userSelector, user => user.hasStore);
const hasInventoriesWithoutStoreSelector = createSelector(userSelector, user => user.hasInventoriesWithoutStore);
export const userHasInventoriesSelector = createSelector(hasInventoriesWithoutStoreSelector, hasStoreSelector, (hasInventories, hasStore) => hasInventories || hasStore);
export const isInventoryTermsAcceptedSelector = () => true;
export const userHasActiveOrOwnDeletedInventorySelector = () => true;
const roleSelector = createSelector(userSelector, user => user.role);
export const isUserPostalSupervisor = createSelector(roleSelector, role => role === USER_ROLES.POSTAL_SUPERVISOR);
export const isCustomInkImpersonateFlowSelector = args => false;