var _window, _window$swag, _window$swag$env;
let POSTAL_URL_PREFIX;
if (typeof window !== 'undefined' && (_window = window) !== null && _window !== void 0 && (_window$swag = _window.swag) !== null && _window$swag !== void 0 && (_window$swag$env = _window$swag.env) !== null && _window$swag$env !== void 0 && _window$swag$env.POSTAL_URL_PREFIX) {
  POSTAL_URL_PREFIX = window.swag.env.POSTAL_URL_PREFIX.replace(/\/$/, '');
} else {
  if (typeof postal_url_prefix !== 'undefined') {
    /** "postal_url_prefix" defined with [`webpack.DefinePlugin`](https://webpack.js.org/plugins/define-plugin/) */
    POSTAL_URL_PREFIX = postal_url_prefix.replace(/\/$/, '');
  }
}
export function urlModifier(url) {
  if (POSTAL_URL_PREFIX) {
    return `${POSTAL_URL_PREFIX}${url}`;
  }
  return url;
}
export function urlReplacer(url) {
  if (POSTAL_URL_PREFIX) {
    return url.replace(POSTAL_URL_PREFIX, '');
  }
  return url;
}
export const POSTAL_ORIGIN_SUFIX = POSTAL_URL_PREFIX;
export const SOCKET_PATH = POSTAL_URL_PREFIX ? `${POSTAL_URL_PREFIX}/socket.io/` : null;
export const TRANSPORTS = ['websocket'];
export function getCartLinkId() {
  return '';
}
export function cartUrlModifier(url) {
  return url;
}