function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SAVE_FUNDING_SOURCE, GET_FUNDING_SOURCE_REQUEST, GET_FUNDING_SOURCE_ERROR, GET_FUNDING_SOURCE_SUCCESS } from './checkout.actions-other.postal';
import { checkoutReducer as checkoutMainReducer } from './checkout-main.reducer';
import { initialState } from './initial-state-params.postal';
import { PAYMENT_POPUP_CLOSE, PROCEED_ORDER_FAIL, PROCEED_ORDER_SUCCESS } from './checkout.actions';
export default function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_FUNDING_SOURCE:
      {
        return _objectSpread({}, state, {
          fundingSource: action.payload
        });
      }
    case GET_FUNDING_SOURCE_REQUEST:
      {
        return _objectSpread({}, state, {
          isFundingSourcesLoading: true,
          fundingSource: null
        });
      }
    case GET_FUNDING_SOURCE_SUCCESS:
      {
        return _objectSpread({}, state, {
          fundingSourcesList: action.payload,
          isFundingSourcesLoading: false
        });
      }
    case GET_FUNDING_SOURCE_ERROR:
      {
        return _objectSpread({}, state, {
          err: action.payload,
          isFundingSourcesLoading: false
        });
      }
    case PROCEED_ORDER_SUCCESS:
    case PROCEED_ORDER_FAIL:
    case PAYMENT_POPUP_CLOSE:
      {
        return _objectSpread({}, state, checkoutMainReducer(state, action), {
          fundingSource: null
        });
      }
    default:
      {
        return _objectSpread({}, checkoutMainReducer(state, action), {
          isFundingSourcesLoading: state.isFundingSourcesLoading,
          fundingSource: state.fundingSource,
          fundingSourcesList: state.fundingSourcesList
        });
      }
  }
}